const meta = {
    home: {
        docTitle: 'Rob Linacre',
        canonical: '',
        description: 'Vocal Entertainer',
    },
    gallery: {
        docTitle: 'Gallery',
        canonical: '/gallery',
        description: 'Vocal Entertainer',
    },
    videos: {
        docTitle: 'Videos',
        canonical: '/videos',
        description: 'Vocal Entertainer',
    },
    music: {
        docTitle: 'Music',
        canonical: '/music',
        description: 'Vocal Entertainer',
    },
    about: {
        docTitle: 'About Rob',
        canonical: '/about',
        description: 'Vocal Entertainer',
    },
    contact: {
        docTitle: 'Contact Rob',
        canonical: '/contact',
        description: 'Contact Rob Linacre',
    },
};

export default meta;
