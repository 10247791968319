export const companyInfo = {
    name: 'Rob Linacre',
    docTitlePrefix: 'Rob Linacre - ',
    siteUrl: 'https://roblinacre.com/',
};

const MISC = {
    toaster: {
        error: {
            title: 'An error has occurred',
            content: 'Please refresh the page as something has gone wrong!',
        },
    },
    currency: '£',
    monthly: 'monthly',
    annually: 'yearly',
    important: 'Important',
    yes: 'Yes',
    no: 'No',
};

export default MISC;
