import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/';
import * as serviceWorker from './serviceWorker';

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';

const toastProps = {
    autoClose: false,
    position: toast.POSITION.TOP_CENTER,
    transition: Slide,
};

ReactDOM.render(
    <BrowserRouter basename="">
        <Routes />
        <ToastContainer {...toastProps} />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
