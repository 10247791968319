import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMusic,
    faVideo,
    faImages,
    faAt,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const IconLink = ({ icon, label, url }) => (
    <Link to={url}>
        <div className="sticky-menu-bar__icon">
            <FontAwesomeIcon icon={icon} size="lg" color="white" />
            <div className="sticky-menu-bar__icon--title">{label}</div>
        </div>
    </Link>
);

const StickyMenuBar = () => {
    return (
        <div className="sticky-menu-bar">
            <IconLink icon={faInfoCircle} label="About" url="/about" />
            <IconLink icon={faMusic} label="Music" url="/music" />
            <IconLink icon={faVideo} label="Video" url="/videos" />
            <IconLink icon={faImages} label="Gallery" url="/gallery" />
            <IconLink icon={faAt} label="Contact" url="/contact" />
        </div>
    );
};

export default StickyMenuBar;
