import React from 'react';
import YouTube from 'react-youtube';
import { LoadingSpinner } from 'components';

const youtubeIds = [
    'HJVKp-_IU5s',
    'olty-0kaGM4',
    'vdc779TdMx4',
    'Y6pK94BShR4',
    'XHkACy6rzYc',
    'DztC_wnJaLo',
    'qvJmmk_jLFg',
];

const videos = youtubeIds.map(video => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };
    return (
        <div className="video-container" key={video}>
            <div className="loader">
                <LoadingSpinner />
            </div>
            <YouTube videoId={video} opts={opts} />
        </div>
    );
});

const GalleryVideos = () => {
    return <div className="gallery-videos inner-content">{videos}</div>;
};

export default GalleryVideos;
