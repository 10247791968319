/* istanbul ignore file */
import * as Yup from 'yup';
import COPY from './contactFormCopy';
import { checkEmail } from 'utils/commonUtils';

const { validationErrorMessages: error } = COPY;

const EMAIL_VALIDATION = Yup.string()
    .required(error.required)
    .email(error.emailInvalid)
    .test('valid_email', error.emailInvalid, value => checkEmail(value));

export const CONTACT_FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string().required(error.required),
    lastName: Yup.string().required(error.required),
    emailAddress: EMAIL_VALIDATION,
    message: Yup.string().required(error.required),
});
