export const aboutPageStrap = {
    title: 'Gallery',
    blurb: ['A few memories from Rob’s career in music…'],
};

export const galleryTabs = ['Performing', 'My life at sea'];

export const galleryImages = [
    {
        fileName: 'gallery01',
        location: 'performing',
        active: false,
    },
    {
        fileName: 'gallery02',
        location: 'performing',
        active: false,
    },
    {
        fileName: 'gallery03',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery05',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery06',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery07',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery08',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery09',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery10',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery11',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery12',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery13',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery14',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery15',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery16',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery17',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery18',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery19',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery20',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery21',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery22',
        location: 'performing',
        active: true,
    },
    {
        fileName: 'gallery100',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery101',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery102',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery103',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery104',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery105',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery106',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery107',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery108',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery109',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery110',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery111',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery112',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery113',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery114',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery115',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery116',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery117',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery118',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery119',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery120',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery121',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery122',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery123',
        location: 'at-sea',
        active: true,
    },
    {
        fileName: 'gallery200',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery201',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery202',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery203',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery204',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery205',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery200',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery206',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery207',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery208',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery209',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery210',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery211',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery212',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery213',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery214',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery215',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery216',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery217',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery218',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery219',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery220',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery221',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery222',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery223',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery224',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery225',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery226',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery227',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery228',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery229',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery230',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery231',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery232',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery233',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery234',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery235',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery236',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery237',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery238',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery239',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery240',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery241',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery242',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery243',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery244',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery245',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery246',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery247',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery248',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery249',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery250',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery251',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery252',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery253',
        location: 'misc',
        active: true,
    },
    {
        fileName: 'gallery254',
        location: 'misc',
        active: true,
    },
];
