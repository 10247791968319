import React from 'react';
import Masonry from 'react-masonry-css';
import { galleryImages } from 'constants/containers/gallery';

const breakpointColumnsObj = {
    default: 3,
    1024: 3,
    768: 2,
    576: 1,
};

// Create the gallery img elements
const gallery = ({ location }) => {
    const imageArray = galleryImages.filter(
        i => i.location === location && i.active
    );
    return imageArray.map(i => {
        const path = `img/gallery/${i.location}/${i.fileName}.jpg`;
        return <img src={path} key={path} alt="Gallery" />;
    });
};

const GalleryImages = ({ location }) => {
    return (
        <div className="gallery-images inner-content">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {gallery({ location })}
            </Masonry>
        </div>
    );
};

export default GalleryImages;
