import React from 'react';

const AboutContent = ({ content }) => {
    console.log('content', content);
    const renderedContent = content.blurb.map((i, idx) => (
        <p key={idx}>
            {i.imageUrl && (
                <img src={i.imageUrl} className={i.imageClass} alt="Mick" />
            )}
            {i.para}
        </p>
    ));
    return (
        <div className="inner-content about">
            <div className="about-content">{renderedContent}</div>
        </div>
    );
};

export default AboutContent;
