const COPY = {
    fieldLabels: {
        firstName: 'First name',
        lastName: 'Last name',
        phoneNumber: 'Phone number (optional)',
        emailAddress: 'Email address',
        companyName: 'Company name (optional)',
        message: 'Message',
    },
    validationErrorMessages: {
        required: 'This field is required',
        emailInvalid: 'That email address is not valid',
    },
    externalLinks: {},
    misc: {},
    copy: {
        title: 'Want to discuss a new project?',
    },
    CTA: {
        submit: 'Submit',
    },
    tooltips: {},
    selectLists: {
        default: { label: 'Select from list', value: '' },
    },
    apiCalls: {
        contactUs: '/api/contact-us',
    },
    toastCopy: {
        emailFail: {
            title: 'Oops! Something went wrong',
            content: [
                'Apologies, something went wrong, in the meantime, please give us a call instead.',
            ],
        },
        emailSuccess: {
            title: 'Great!',
            content: [
                'Your details have been sent to us. We will respond as soon as possible.',
            ],
        },
    },
};

export default COPY;
