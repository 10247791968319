import React from 'react';
import { Formik, Form, Field } from 'formik';
import { CONTACT_FORM_VALIDATION } from './contactFormValidation';
import {
    TextInputField,
    SubmitButton,
    TextareaCharacterCount,
    MaskedInputField,
} from 'components/FormInputs';
import COPY from './contactFormCopy';
import { API } from 'utils/apiCall';
import { ToastContent } from 'components/';
import { toast } from 'react-toastify';

const { fieldLabels, CTA, copy, apiCalls, toastCopy } = COPY;

const phoneNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

const submitContactForm = async payload => {
    await API.call(apiCalls.contactUs, payload, 'post').then(response => {
        const resp = response.data;
        if (!response) {
            return false;
        }
        const { error } = resp;
        switch (true) {
            case error:
                toast.error(<ToastContent {...toastCopy.emailFail} />);
                break;
            default:
                console.log('here');
                toast.success(<ToastContent {...toastCopy.emailSuccess} />);
                break;
        }
    });
};

const noop = () => {};

const ContactForm = () => {
    const initialValues = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: '',
        companyName: '',
        message: '',
    };
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={CONTACT_FORM_VALIDATION}
            onSubmit={values => submitContactForm(values)}
        >
            {() => {
                return (
                    <div className="inner-content">
                        <Form className="contact-form" id="loginForm">
                            <h2>{copy.title}</h2>
                            <div className="contact-form__name">
                                <Field
                                    component={TextInputField}
                                    labelText={fieldLabels.firstName}
                                    fieldType="text"
                                    fieldName="firstName"
                                    placeHolder="John"
                                />
                                <Field
                                    component={TextInputField}
                                    labelText={fieldLabels.lastName}
                                    fieldType="text"
                                    fieldName="lastName"
                                    placeHolder="Smith"
                                />
                            </div>
                            <div className="contact-form__contact-details">
                                <Field
                                    component={MaskedInputField}
                                    labelText={fieldLabels.phoneNumber}
                                    fieldType="text"
                                    fieldName="phoneNumber"
                                    mask={phoneNumberMask}
                                    guide={false}
                                    placeHolder="0788 123 4567"
                                />
                                {/* <Field
                                    component={TextInputField}
                                    labelText={fieldLabels.phoneNumber}
                                    fieldType="text"
                                    fieldName="phoneNumber"
                                    placeHolder="+44"
                                /> */}
                                <Field
                                    component={TextInputField}
                                    labelText={fieldLabels.emailAddress}
                                    fieldType="email"
                                    fieldName="emailAddress"
                                    placeHolder="john.smith@gmail.com"
                                />
                            </div>
                            <Field
                                component={TextInputField}
                                labelText={fieldLabels.companyName}
                                fieldType="text"
                                fieldName="companyName"
                                placeHolder="Your company name"
                                customClass="full-width"
                            />
                            <Field
                                component={TextareaCharacterCount}
                                labelText={fieldLabels.message}
                                characterLimit={255}
                                fieldName="message"
                                customClass="textarea-container"
                            />
                            <div className="login__form__button-holder">
                                <SubmitButton
                                    buttonCopy={CTA.submit}
                                    onClick={noop}
                                />
                            </div>
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

export default ContactForm;
