/*
 * Exporting all the components from the components folder,
 * components can be imported as the example below:
 * import { Logo, Avatar } from 'components';
 */

export { default as Header } from './Header';
export { default as Footer } from './Footer';
export { default as PageStrap } from './PageStrap';
export { default as ContactBlock } from './ContactBlock';
export { default as ContactDetails } from './ContactDetails';
export { default as ContactForm } from './ContactForm';
export { default as DoubleBlock } from './DoubleBlock';
export { default as DoubleBlockContainer } from './DoubleBlockContainer';
export { default as AboutContent } from './AboutContent';
export { default as GalleryImages } from './GalleryImages';
export { default as GalleryVideos } from './GalleryVideos';
export { default as Music } from './Music';
export { default as HomePageHero } from './HomePageHero';
export { default as StickyMenuBar } from './StickyMenuBar';
export { default as GalleryTabs } from './GalleryTabs';
