import React, { Suspense, lazy, useLayoutEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Layout from '../layouts/';
import Helmet from 'react-helmet';
import meta from 'constants/seo';
import { companyInfo } from 'constants/misc';

const Home = lazy(() => import('../containers/Home'));
const About = lazy(() => import('../containers/About'));
const Contact = lazy(() => import('../containers/Contact'));
const Music = lazy(() => import('../containers/Music'));
const Videos = lazy(() => import('../containers/Videos'));
const Gallery = lazy(() => import('../containers/Gallery'));

const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return <>{children}</>;
};

const renderWithLayout = (Component, Layout, props, page = '') => {
    const { siteUrl, docTitlePrefix } = companyInfo;
    const pageMeta = meta[page];
    const { docTitle, description, canonical } = pageMeta;
    const currentUrl = window.location.href;

    const pageTitle = `${docTitlePrefix}${docTitle}`;
    const pageUrl = `${siteUrl}${canonical}`;

    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={pageUrl} />
                <meta
                    property="og:image"
                    content={`${currentUrl}img/heros/hp-hero.webp`}
                />
                <meta property="og:description" content={description} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:url" content={pageUrl} />
            </Helmet>
            <Component {...props} />
        </Layout>
    );
};

const Routes = () => {
    return (
        <Suspense fallback={''}>
            <Switch>
                <ScrollToTop>
                    <Route
                        path="/"
                        exact
                        component={props =>
                            renderWithLayout(Home, Layout, props, 'home')
                        }
                    />
                    <Route
                        path="/about"
                        exact
                        component={props =>
                            renderWithLayout(About, Layout, props, 'about')
                        }
                    />
                    <Route
                        path="/contact"
                        exact
                        component={props =>
                            renderWithLayout(Contact, Layout, props, 'contact')
                        }
                    />
                    <Route
                        path="/music"
                        exact
                        component={props =>
                            renderWithLayout(Music, Layout, props, 'music')
                        }
                    />
                    <Route
                        path="/videos"
                        exact
                        component={props =>
                            renderWithLayout(Videos, Layout, props, 'videos')
                        }
                    />
                    <Route
                        path="/gallery"
                        exact
                        component={props =>
                            renderWithLayout(Gallery, Layout, props, 'gallery')
                        }
                    />
                </ScrollToTop>
            </Switch>
        </Suspense>
    );
};

export default Routes;
