import React from 'react';

const createMarkup = para => {
    return { __html: para };
};

const ContactDetails = ({ copy }) => {
    const { paras } = copy;
    return (
        <div className="contact-details__contact">
            <div className="inner-content">
                {paras.map(para => (
                    <p
                        className="contact-details__contact__item"
                        dangerouslySetInnerHTML={createMarkup(para)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ContactDetails;
