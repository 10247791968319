import React from 'react';

const HomePageHero = () => {
    return (
        <div className="homepage-hero">
            <div className="homepage-hero__copy">
                <div className="inner-content"></div>
            </div>
        </div>
    );
};

export default HomePageHero;
