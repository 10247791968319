import React from 'react';

const soundcloudIds = [
    147037500,
    145879285,
    145879306,
    147057900,
    147057909,
    //147059832,
];

const SoundCloudMusic = () =>
    soundcloudIds.map(id => (
        <iframe
            key={id}
            title="music"
            width="100%"
            height="166"
            scrolling="no"
            frameborder="no"
            //show_teaser={false}
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&amp;color=000000&amp;auto_play=false&amp;hide_related=false&amp;show_artwork=true&show_teaser=false`}
        ></iframe>
    ));

const Music = () => {
    return (
        <div className="inner-content music">
            <SoundCloudMusic />
        </div>
    );
};

export default Music;
