import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const GalleryTabs = ({ images1, images2, images3 }) => {
    return (
        <Tabs>
            <TabList>
                <Tab>Performing</Tab>
                <Tab>My life at sea</Tab>
                <Tab>Misc</Tab>
            </TabList>

            <TabPanel>{images1}</TabPanel>
            <TabPanel>{images2}</TabPanel>
            <TabPanel>{images3}</TabPanel>
        </Tabs>
    );
};

export default GalleryTabs;
