import React from 'react';
import { push as Menu } from 'react-burger-menu';
import HeaderCopy from 'constants/modules/Header.js';
import { Link } from 'react-router-dom';
import { GetCurrentPage } from 'utils/commonUtils';

const { headerLinks } = HeaderCopy;

// const links = headerLinks
//     .filter(link => link.active)
//     .map(link => (
//         <li key={link.linkTo}>
//             <Link to={link.linkTo}>{link.label}</Link>
//         </li>
//     ));

const Links = () => {
    const currentPage = GetCurrentPage();
    return headerLinks
        .filter(link => link.active)
        .filter(link => link.sideNav)
        .map(link => {
            const isActive = currentPage === link.linkTo;
            return (
                <li key={link.linkTo}>
                    <Link
                        to={link.linkTo}
                        className="my-auto"
                        aria-current={isActive}
                    >
                        {link.label}
                    </Link>
                    {link.children && (
                        <ul className="sub-links">
                            {link.children.map((subLink, idx) => {
                                const isActive = currentPage === subLink.linkTo;
                                return (
                                    <li key={idx}>
                                        <Link
                                            to={subLink.linkTo}
                                            className="my-auto"
                                            aria-current={isActive}
                                        >
                                            {subLink.label}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </li>
            );
        });
};

export default props => (
    <Menu {...props}>
        {
            <>
                <Link to="/">
                    <img
                        src="/img/brand/logo_color.png"
                        className="logo"
                        alt="Company logo"
                    />
                </Link>
                <ul className="header-side-nav">
                    <Links />
                </ul>
            </>
        }
    </Menu>
);
