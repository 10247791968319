import React from 'react';
import PropTypes from 'prop-types';

const ToastContent = props => {
    const { title, content } = props;
    const ContentDisplay = () => content.map((para, idx) => <p key={idx}>{para}</p>);
    return (
        <div className="toast-content">
            <h4 className="toast-content__title">{title}</h4>
            <div className="toast-content__body">
                <ContentDisplay />
            </div>
        </div>
    );
};

ToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.array.isRequired,
};

export default ToastContent;
